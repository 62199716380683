export const kanaData = {
    basic: [
        { hiragana: 'あ', katakana: 'ア', romaji: 'a' },
        { hiragana: 'い', katakana: 'イ', romaji: 'i' },
        { hiragana: 'う', katakana: 'ウ', romaji: 'u' },
        { hiragana: 'え', katakana: 'エ', romaji: 'e' },
        { hiragana: 'お', katakana: 'オ', romaji: 'o' },
        { hiragana: 'か', katakana: 'カ', romaji: 'ka' },
        { hiragana: 'き', katakana: 'キ', romaji: 'ki' },
        { hiragana: 'く', katakana: 'ク', romaji: 'ku' },
        { hiragana: 'け', katakana: 'ケ', romaji: 'ke' },
        { hiragana: 'こ', katakana: 'コ', romaji: 'ko' },
        { hiragana: 'さ', katakana: 'サ', romaji: 'sa' },
        { hiragana: 'し', katakana: 'シ', romaji: 'shi' },
        { hiragana: 'す', katakana: 'ス', romaji: 'su' },
        { hiragana: 'せ', katakana: 'セ', romaji: 'se' },
        { hiragana: 'そ', katakana: 'ソ', romaji: 'so' },
        { hiragana: 'た', katakana: 'タ', romaji: 'ta' },
        { hiragana: 'ち', katakana: 'チ', romaji: 'chi' },
        { hiragana: 'つ', katakana: 'ツ', romaji: 'tsu' },
        { hiragana: 'て', katakana: 'テ', romaji: 'te' },
        { hiragana: 'と', katakana: 'ト', romaji: 'to' },
        { hiragana: 'な', katakana: 'ナ', romaji: 'na' },
        { hiragana: 'に', katakana: 'ニ', romaji: 'ni' },
        { hiragana: 'ぬ', katakana: 'ヌ', romaji: 'nu' },
        { hiragana: 'ね', katakana: 'ネ', romaji: 'ne' },
        { hiragana: 'の', katakana: 'ノ', romaji: 'no' },
        { hiragana: 'は', katakana: 'ハ', romaji: 'ha' },
        { hiragana: 'ひ', katakana: 'ヒ', romaji: 'hi' },
        { hiragana: 'ふ', katakana: 'フ', romaji: 'fu' },
        { hiragana: 'へ', katakana: 'ヘ', romaji: 'he' },
        { hiragana: 'ほ', katakana: 'ホ', romaji: 'ho' },
        { hiragana: 'ま', katakana: 'マ', romaji: 'ma' },
        { hiragana: 'み', katakana: 'ミ', romaji: 'mi' },
        { hiragana: 'む', katakana: 'ム', romaji: 'mu' },
        { hiragana: 'め', katakana: 'メ', romaji: 'me' },
        { hiragana: 'も', katakana: 'モ', romaji: 'mo' },
        { hiragana: 'や', katakana: 'ヤ', romaji: 'ya' },
        { hiragana: 'ゆ', katakana: 'ユ', romaji: 'yu' },
        { hiragana: 'よ', katakana: 'ヨ', romaji: 'yo' },
        { hiragana: 'ら', katakana: 'ラ', romaji: 'ra' },
        { hiragana: 'り', katakana: 'リ', romaji: 'ri' },
        { hiragana: 'る', katakana: 'ル', romaji: 'ru' },
        { hiragana: 'れ', katakana: 'レ', romaji: 're' },
        { hiragana: 'ろ', katakana: 'ロ', romaji: 'ro' },
        { hiragana: 'わ', katakana: 'ワ', romaji: 'wa' },
        { hiragana: 'を', katakana: 'ヲ', romaji: 'wo' },
        { hiragana: 'ん', katakana: 'ン', romaji: 'n' }
      ],
      voiced: [
        { hiragana: 'が', katakana: 'ガ', romaji: 'ga' },
        { hiragana: 'ぎ', katakana: 'ギ', romaji: 'gi' },
        { hiragana: 'ぐ', katakana: 'グ', romaji: 'gu' },
        { hiragana: 'げ', katakana: 'ゲ', romaji: 'ge' },
        { hiragana: 'ご', katakana: 'ゴ', romaji: 'go' },
        { hiragana: 'ざ', katakana: 'ザ', romaji: 'za' },
        { hiragana: 'じ', katakana: 'ジ', romaji: 'ji' },
        { hiragana: 'ず', katakana: 'ズ', romaji: 'zu' },
        { hiragana: 'ぜ', katakana: 'ゼ', romaji: 'ze' },
        { hiragana: 'ぞ', katakana: 'ゾ', romaji: 'zo' },
        { hiragana: 'だ', katakana: 'ダ', romaji: 'da' },
        { hiragana: 'ぢ', katakana: 'ヂ', romaji: 'ji' },
        { hiragana: 'づ', katakana: 'ヅ', romaji: 'zu' },
        { hiragana: 'で', katakana: 'デ', romaji: 'de' },
        { hiragana: 'ど', katakana: 'ド', romaji: 'do' },
        { hiragana: 'ば', katakana: 'バ', romaji: 'ba' },
        { hiragana: 'び', katakana: 'ビ', romaji: 'bi' },
        { hiragana: 'ぶ', katakana: 'ブ', romaji: 'bu' },
        { hiragana: 'べ', katakana: 'ベ', romaji: 'be' },
        { hiragana: 'ぼ', katakana: 'ボ', romaji: 'bo' },
        { hiragana: 'ぱ', katakana: 'パ', romaji: 'pa' },
        { hiragana: 'ぴ', katakana: 'ピ', romaji: 'pi' },
        { hiragana: 'ぷ', katakana: 'プ', romaji: 'pu' },
        { hiragana: 'ぺ', katakana: 'ペ', romaji: 'pe' },
        { hiragana: 'ぽ', katakana: 'ポ', romaji: 'po' }
      ]
  };